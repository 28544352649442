@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./satoshi.css");
@import url("./kaisei.css");
@import url("./animations.css");

html {
  font-family: "Satoshi-Variable", sans-serif;
  scroll-behavior: smooth;
}
