@font-face {
  font-family: "KaiseiDecol-Regular";
  src: url("../static/fonts/KaiseiDecol-Regular.woff2") format("woff2"),
    url("../static/fonts/KaiseiDecol-Regular.woff") format("woff"),
    url("../static/fonts/KaiseiDecol-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "KaiseiDecol-Medium";
  src: url("../static/fonts/KaiseiDecol-Medium.woff2") format("woff2"),
    url("../static/fonts/KaiseiDecol-Medium.woff") format("woff"),
    url("../static/fonts/KaiseiDecol-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "KaiseiDecol-Bold";
  src: url("../static/fonts/KaiseiDecol-Bold.woff2") format("woff2"),
    url("../static/fonts/KaiseiDecol-Bold.woff") format("woff"),
    url("../static/fonts/KaiseiDecol-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
