.menuIcon {
  cursor: pointer;
  display: block;
  height: 24px;
  width: 24px;
  position: relative;
}

.bar,
.bar::before,
.bar::after {
  background-color: #fff;
  content: "";
  display: block;
  position: absolute;
  transition: transform ease 0.4s;
  transform-origin: bottom left;
  height: 2px;
  width: 18px;
  border-radius: 2px;
}

.bar {
  left: 0;
  top: calc(50% - 1px);
  width: 14px;
  display: flex;
  align-items: flex-end;
}

.bar::before {
  transform: translate(-4px, -6px);
}

.bar::after {
  transform: translate(-4px, 6px);
}

.active .bar {
  background-color: transparent;
}

.active .bar::before {
  transform: rotate(45deg) translate(0px, -10px);
}

.active .bar::after {
  transform: rotate(-45deg) translate(2px, 10px);
}
