@tailwind utilities;
@tailwind variants;

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation: fadeOut 0.2s ease forwards;
  animation: fadeOut 0.2s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 0.2s ease forwards;
  animation: fadeIn 0.2s ease forwards;
}

.fadeInSection {
  -webkit-animation: fadeIn 0.7s 0.2s ease forwards;
  animation: fadeIn 0.7s 0.2s ease forwards;
}

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.fadeInUp {
  -webkit-animation: fadeInUp 0.5s 0.2s ease forwards;
  animation: fadeInUp 0.5s 0.2s ease forwards;
}

.fadeInUpInitial {
  -webkit-transform: translateY(150px);
  transform: translateY(150px);
  opacity: 0;
}

@keyframes fadeInLeft {
  0% {
    -webkit-transform: translate(100%, 50%) scale(1.5);
    transform: translate(100%, 50%) scale(1.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) scale(1);
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}

.fadeInLeft {
  -webkit-animation: fadeInLeft 0.2s ease-out backwards;
  animation: fadeInLeft 0.2s ease-out backwards;
}

@keyframes fadeInRight {
  0% {
    -webkit-transform: translate(-100%, 50%) scale(1.5);
    transform: translate(-100%, 50%) scale(1.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) scale(1);
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(calc(100vh + 100%));
    transform: translateY(calc(100vh + 100%));
  }
}

.slideOutDown {
  -webkit-animation: slideOutDown 0.3s ease-in-out backwards;
  animation: slideOutDown 0.3s ease-in-out backwards;
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(calc(100vh + 100%));
    transform: translateY(calc(100vh + 100%));
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.slideInUp {
  -webkit-animation: slideInUp 0.3s ease-in-out backwards;
  animation: slideInUp 0.3s ease-in-out backwards;
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.slideInLeft {
  -webkit-animation: slideInLeft 0.15s ease-in-out backwards;
  animation: slideInLeft 0.15s ease-in-out backwards;
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.slideInRight {
  -webkit-animation: slideInRight 0.15s ease-in-out backwards;
  animation: slideInRight 0.15s ease-in-out backwards;
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0;
  }
}

.slideOutLeft {
  -webkit-animation: slideOutLeft 0.15s ease-in-out backwards;
  animation: slideOutLeft 0.15s ease-in-out backwards;
}

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slideOutRight {
  -webkit-animation: slideOutRight 0.15s ease-in-out backwards;
  animation: slideOutRight 0.15s ease-in-out backwards;
}

@variants responsive {
  .fadeInRight {
    -webkit-animation: fadeInRight 0.2s ease-out backwards;
    animation: fadeInRight 0.2s ease-out backwards;
  }
}

@keyframes arrowScaleDown {
  100% {
    transform: scale(1) translate(0, 0);
  }
}

.arrowScaleDown {
  transition: transform;
  animation: arrowScaleDown ease 0.2s forwards;
}
